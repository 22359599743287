<template>
  <p-container class="no-bg">  
    <template slot="left" v-if="showLeft">
      <a-tree
        :show-line="true"
        :default-expanded-keys="['0-0']"
        :tree-data="treeData"
      ></a-tree>
    </template>
    <template slot="toolbar">
      <!-- <a-button type="primary" @click="handleEdit">编辑</a-button>
      <a-button type="primary">Excel导出</a-button> -->
      <a-button type="primary" @click="addData">添加</a-button>
      <a-button type="primary" @click="handledelat">删除</a-button>
      <!-- <a-button type="primary">批量分类梳理</a-button>
      <a-button type="primary">申请号批量查询</a-button>
      <a-button type="primary">启动评审</a-button> -->
    </template>
    
    <a-table
      bordered
      :data-source="dataSource"
      :columns="columns"
      size="middle"
      :row-selection="rowSelection"
      :loading="loading"
    >
      <a
        href="javascript:;"
        @click="webShowModal(col)"
        slot="webAction"
        slot-scope="text, col"
        >编辑</a
      >
    </a-table>    
    
     <a-input-search
        
        placeholder="搜索"
        enter-button="搜索"
        size="default"
        @search="onSearch"
      />
      <a-button type="primary" @click="gather">采集</a-button>
    <a-table
      bordered
      :data-source="dataSource1"
      :columns="columns1"
      size="middle"
      :row-selection="rowSelection"
      :loading="loading"
    >
    <a
        href="javascript:;"
        @click="webShowModal(col)"
        slot="webAction"
        slot-scope="text, col"
        >{{ text }}</a
      >
        <a
        href="javascript:;"
        @click="webShowModals(col)"
        slot="webActions"
        slot-scope="text, col"
        >{{ text }}</a
      >
      <a
        href="javascript:;"
        @click="webShowModalss(col)"
        slot="webActionss"
        slot-scope="text, col"
        >{{ text }}</a
      >
    </a-table>  
     <a-modal v-model="webVisible" title="编辑" @ok="handleOk">
        <div>
        <a-input-group>
          <a-row :gutter="20" :data-source="dataSources" >
            <a-col :span="30">
                <a-form-item label="类别">
                  <a-textarea v-model="lb" @input="autoTextarea" :disabled="flase" :span="30">
                   </a-textarea>
                </a-form-item>
                <a-form-item label="地址">
                  <a-textarea v-model="url" @input="autoTextarea" :disabled="flase" :span="30">
                   </a-textarea>
                </a-form-item>
              </a-col>
          </a-row>
          
       </a-input-group>
        </div>
      <div>
      </div>
    </a-modal>

  </p-container> 
</template>
<script>
import {getzcxx,delatezcxx,getdtxx,addzcxx, getgather} from '../../../../api/result'
let selectedRowsss = []
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowsss = selectedRows.map(item => {
      return {
        keyguid: item.keyguid,
        lb: item.lb,
        url: item.url,
      }
    })
  },
  onSelect: (record, selected, selectedRows) => {
        localStorage.removeItem("zcxxArry")
      var zcxxArry=[]
    
    selectedRows.forEach(item => {
      var cgobj={}
     cgobj.keyguid=item.keyguid
  zcxxArry.push(cgobj)
    });
      localStorage.setItem('zcxxarry',JSON.stringify(zcxxArry))
     
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
          localStorage.removeItem("zcxxArry")
     
     localStorage.setItem('zcxxarry',JSON.stringify(zcxxArry))
   
  },
};
const columns = [
  {
    title: "",
    dataIndex: "edit",
    width: "10%",
    scopedSlots: { customRender: "webAction" },
  },
  
  {
    title: "搜索网址",
    dataIndex: "url",
  },
];
const dataSource = [
        {
          key: "0",
          edit: "编辑",
          category: "类别1",
          searchWeb: "奖励政策",
        },
]
const columns1 = [
 
  {
    title: "标题",
    dataIndex: "title",
       ellipsis: true 
  },
  {
    title: "搜索网址",
    dataIndex: "url",
       ellipsis: true ,
       scopedSlots: { customRender: "webActions" },
  },
  {
    title: "政策爬虫网址",
    dataIndex: "sUrl",
       ellipsis: true ,
        scopedSlots: { customRender: "webActionss" },
  },
];
const dataSource1 = [
        {
          key: "0",
          edit: "编辑",
          category: "类别1",
          searchWeb: "奖励政策",
          searchInfo: "数据1"
        },
]

export default {
    name:'QuickSearch',
    data() {
      return {
        loading: false,
        treeData: [],
        rowSelection,
        dataSource,
        columns,
        dataSource1,
        columns1,
        webVisible:false,
        lb:'',
        url:'',
        keyguid:'',
        url2:'apia/zhengceSs/searchZcUrl?search=',
        isAdd: false,
        selectedRows: []
      }
    },
      computed: {
      type(){
          return this.$route.name.substr(this.$route.name.lastIndexOf("_")+1);
      },
      // showLeft(){
      //   return this.type!='all'
      // }
  },
  mounted(){
    getzcxx()
  .then(res=>{
  this.dataSource=res.data
    })
     getdtxx(this.url2)
        .then(res=>{
          console.log(res)
          this.dataSource1=res.data
        })
  },
  watch: {
    $route(to, from) {
      this.initPage();
    },
  },
  created() {
    this.initPage();
  },
    methods: {
      gather() {
        getgather(selectedRowsss, '')
        .then(res=>{
          console.log(res)
          this.dataSource1=res.data
        })
      },
      initPage() {
      //tree
      switch (this.type) {
        case "subject":
          this.treeData = subjectTreeData;
          break;
        case "device":
          this.treeData = deviceTreeData;
          break;
        default:
          break;
      }
      //clear searchbar

      //loadList
    },
    
    addData() {
      this.isAdd = true
      this.webVisible = true;
    },
    webShowModal(col) {
      this.isAdd = false
      this.webVisible = true;
      this.mdl = { ...col }
      console.log(col)
      this.url=col.url
      this.lb=col.lb
      this.keyguid=col.keyguid
    },
      handleOk(e) {
        if (this.isAdd) {
          this.webVisible = false;

          addzcxx({
            keyguid:this.keyguid,
            url:this.url,
            lb:this.lb
          }).then(res => {
            this.dataSource=res.data
          })
        } else {
          alert('j')
          this.webVisible = false;
          bjzcxx({
            keyguid:this.keyguid,
            url:this.url,
            lb:this.lb
          })
          .then(res=>{
            this.dataSource=res.data
          })
        }
      },
      handledelat(){
        console.log(JSON.parse(localStorage.getItem('zcxxarry')))
        delatezcxx(JSON.parse(localStorage.getItem('zcxxarry')))
        .then(res=>{
          this.dataSource=res.data
        })
      },
      onSearch(e){
        getgather([], e)
        .then(res=>{
          console.log(res)
          this.dataSource1=res.data
        })
        // console.log(e)
        // getdtxx(this.url2+e)
        // .then(res=>{
        //   console.log(res)
        //   this.dataSource1=res.data
        // })
      },
      webShowModals(e){
        console.log(e.url)
        window.location.href = e.url; 
      },
      webShowModalss(e){
        console.log(e)
         window.location.href = e.sUrl; 
      }
      
    }
 
}
</script>

<style scoped>
.ant-input-search {
  
  width: 40%;
  margin: 0 8px 8px 0;
}
</style>